// 產品分類
export const ProductType = {
  Hotel: 0,
  Bnb: 1,
  Ticket: 2,
  Park: 3,
  Travel: 4,
  CruiseShip: 5,
  DayTrip: 6,
  OverSea: 7,
  Deposit: 8,
};

// 優惠種類
export const AttributeType = {
  Region: 0,
  Distance: 1,
  Ticket: 2,
  HotelSaleItem: 3,
};
